export class AnchorScroll {
    constructor(selector) {
        this.initClickEvents(selector);
    }
    initClickEvents(selector) {
        document.querySelectorAll(selector).forEach(anchor => {
            anchor.addEventListener("click", (event) => this.handleClick(event, anchor));
        });
    }
    handleClick(event, anchor) {
        const targetName = anchor.hash.replace("#", "");
        const target = document.querySelector(`a[name="${targetName}"]`);
        if (target) {
            event.preventDefault();
            event.stopPropagation();
            this.scrollToTarget(target);
        }
    }
    scrollToTarget(target) {
        target.scrollIntoView();
    }
}
export function initAnchorScroll() {
    new AnchorScroll("a.has-anchor");
}
