export class VideoPlayer {
    constructor(container) {
        this.overlay = null;
        this.handleEscapePress = (event) => {
            if (event.key === 'Escape') {
                this.closeOverlay();
            }
        };
        this.container = container;
        this.iframe = container.querySelector('.video-teaser__iframe');
        this.videoPlay = container.querySelector('.js-video-preview');
        if (this.videoPlay) {
            this.videoPlay.addEventListener('click', () => this.loadVideo());
            this.videoPlay.addEventListener('keydown', (e) => this.handleKeydown(e));
        }
        this.container.addEventListener('keydown', (e) => this.handleKeydown(e));
        this.container.addEventListener('click', () => this.loadVideo());
    }
    loadVideo() {
        this.stopInlineVideo();
        if (!this.iframe || !this.videoPlay)
            return;
        const isHorizontal = this.container.closest(".video-teaser__wrapper--horizontal") !== null;
        if (isHorizontal) {
            this.createOverlay();
        }
        else {
            this.videoPlay.style.display = 'none';
            this.iframe.style.display = 'block';
            const videoSrc = this.iframe.dataset.framesrc;
            if (videoSrc) {
                this.iframe.src = videoSrc;
            }
        }
    }
    createOverlay() {
        var _a;
        if (this.overlay)
            return;
        this.overlay = document.createElement('div');
        this.overlay.classList.add('video-teaser-overlay');
        const closeButton = document.createElement('button');
        closeButton.classList.add('video-teaser-overlay__close');
        closeButton.innerHTML = '&times;';
        closeButton.addEventListener('click', () => this.closeOverlay());
        const overlayIframe = document.createElement('iframe');
        overlayIframe.classList.add('video-teaser-overlay__iframe');
        overlayIframe.src = ((_a = this.iframe) === null || _a === void 0 ? void 0 : _a.dataset.framesrc) || '';
        overlayIframe.allow = 'autoplay; encrypted-media';
        overlayIframe.allowFullscreen = true;
        this.overlay.appendChild(closeButton);
        this.overlay.appendChild(overlayIframe);
        document.body.appendChild(this.overlay);
        document.addEventListener('keydown', this.handleEscapePress);
        setTimeout(() => {
            var _a;
            (_a = this.overlay) === null || _a === void 0 ? void 0 : _a.classList.add('open');
        }, 10);
    }
    closeOverlay() {
        if (!this.overlay)
            return;
        this.overlay.classList.remove('open');
        document.removeEventListener('keydown', this.handleEscapePress);
        setTimeout(() => {
            var _a, _b;
            const overlayIframe = (_a = this.overlay) === null || _a === void 0 ? void 0 : _a.querySelector('iframe');
            if (overlayIframe && overlayIframe.parentNode) {
                overlayIframe.parentNode.removeChild(overlayIframe);
            }
            (_b = this.overlay) === null || _b === void 0 ? void 0 : _b.remove();
            this.overlay = null;
        }, 300);
    }
    stopInlineVideo() {
        if (this.iframe && this.iframe.parentNode) {
            const newIframe = this.iframe.cloneNode(false);
            newIframe.src = '';
            newIframe.style.display = 'none';
            this.iframe.parentNode.replaceChild(newIframe, this.iframe);
            this.iframe = newIframe;
        }
        if (this.videoPlay) {
            this.videoPlay.style.display = 'block';
        }
    }
    handleKeydown(e) {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            this.loadVideo();
        }
    }
}
export function initVideoiFrame() {
    const videoContainers = document.querySelectorAll('.js-video-container');
    const players = [];
    videoContainers.forEach(container => {
        const player = new VideoPlayer(container);
        players.push(player);
    });
    window.addEventListener('beforeunload', () => {
        players.forEach(player => player['stopInlineVideo']());
    });
}
