export class ScrollHeader {
    constructor(header, content) {
        var _a;
        this.header = header;
        this.content = content;
        this.lastScrollTop = 0;
        this.isHidden = false;
        this.hideThreshold = 0;
        this.mediaQuery920 = window.matchMedia("(min-width: 920px)");
        this.mediaQuery767 = window.matchMedia("(min-width: 768px)");
        this.lastScrollTop = window.scrollY;
        this.header.style.transition = "top 0.4s ease-in-out";
        (_a = this.content) === null || _a === void 0 ? void 0 : _a.style.setProperty("transition", "padding-top 0.4s ease-in-out");
        this.updateSettings();
        window.addEventListener("scroll", () => requestAnimationFrame(() => this.onScroll()));
        window.addEventListener("resize", () => this.updateSettings());
    }
    onScroll() {
        const scrollTop = window.scrollY;
        if (scrollTop > this.lastScrollTop && !this.isHidden) {
            this.header.style.top = "-85px";
            this.isHidden = true;
        }
        else if (scrollTop < this.lastScrollTop && this.isHidden && scrollTop <= this.hideThreshold) {
            this.header.style.top = "0";
            this.isHidden = false;
        }
        this.lastScrollTop = scrollTop;
    }
    updateSettings() {
        if (this.mediaQuery920.matches) {
            this.hideThreshold = 200;
            if (this.content)
                this.content.style.paddingTop = "135px";
        }
        else if (this.mediaQuery767.matches) {
            this.hideThreshold = 0;
            if (this.content)
                this.content.style.paddingTop = "50px";
        }
        else {
            this.hideThreshold = 0;
            if (this.content)
                this.content.style.paddingTop = "0";
        }
    }
}
export function initScrollHeader() {
    const headerElement = document.querySelector(".header-sticky");
    const contentElement = document.querySelector(".content");
    if (headerElement) {
        new ScrollHeader(headerElement, contentElement);
    }
}
