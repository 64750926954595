export class OebbDialogModal {
    constructor(content, showOnCreation = true) {
        this.isOpen = false;
        this.content = content;
        this.initDom();
        this.initListeners();
        this.render();
        if (showOnCreation)
            this.show();
    }
    initDom() {
        if (document.querySelector('.js-oebb-dm-overlay')) {
            this.overlay = document.querySelector('.js-oebb-dm-overlay');
            this.dialog = this.overlay.querySelector('.js-oebb-dm-dialog');
            this.btnClose = this.overlay.querySelector('.js-oebb-dm-close-dialog-modal');
        }
        else {
            this.overlay = document.createElement('div');
            this.overlay.classList.add('oebb-dm-overlay');
            this.overlay.classList.add('js-oebb-dm-overlay');
            this.dialog = document.createElement('div');
            this.dialog.classList.add('oebb-dm-dialog');
            this.dialog.classList.add('js-oebb-dm-dialog');
            this.btnClose = document.createElement('button');
            this.btnClose.classList.add('oebb-dm-btn-close');
            this.btnClose.classList.add('js-oebb-dm-close-dialog-modal');
            this.btnClose.innerHTML = `<span aria-label="Close">&times;</span>`;
            this.overlay.appendChild(this.btnClose);
            this.overlay.appendChild(this.dialog);
            document.body.appendChild(this.overlay);
            this.addAriaAttributes();
        }
    }
    initListeners() {
        this.btnClose.addEventListener('click', () => {
            this.hide();
        });
        this.overlay.addEventListener('click', (e) => {
            if (e.target.classList.contains('js-oebb-dm-overlay')) {
                this.hide();
            }
        });
        document.addEventListener('keyup', (e) => {
            if (e.keyCode != 27)
                return;
            let overlay = document.querySelector('.js-oebb-dm-overlay');
            if (overlay) {
                this.hide();
            }
        });
    }
    addAriaAttributes() {
        this.dialog.setAttribute('role', 'dialog');
        this.dialog.setAttribute('aria-modal', 'true'); // Tell screenreaders that content behind the modal is not interactive
    }
    show() {
        if (this.overlay) {
            document.documentElement.style.overflow = 'hidden';
            document.body.style.overflow = 'hidden';
            this.overlay.style.display = 'block';
            this.isOpen = true;
        }
    }
    hide() {
        if (this.overlay) {
            document.documentElement.style.overflow = 'auto';
            document.body.style.overflow = 'auto';
            this.overlay.style.display = 'none';
            this.isOpen = false;
        }
    }
    isActive() {
        return this.isOpen;
    }
    destroy() {
        this.overlay.parentNode.removeChild(this.overlay);
    }
    render() {
        this.dialog.innerHTML = this.content;
    }
}
export function addOebbDialogModalDefaultStyles() {
    if (document.querySelector('#js-oebb-dm-styles'))
        return;
    const style = document.createElement('style');
    style.id = 'js-oebb-dm-styles';
    style.innerHTML = `
        .oebb-dm-overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 100;

            display: block;
            padding: 50px 15px 20px;
            overflow-y: auto;

            background-color: rgba(0, 0, 0, 0.75);
        }

        .geschaeftsbericht .oebb-dm-overlay {
            top: 56px;
        }

        .oebb-dm-btn-close {
            position: absolute;
            top: 5px;
            right: 5px;

            display: inline-block;
            width: 35px;
            height: 35px;

            background: transparent;
            border: none;
            cursor: pointer;

            color: #ffffff;
            font-size: 35px;
        }

        .oebb-dm-dialog {
            position: relative;

            display: block;
            width: 100%;
            max-width: 900px;
            margin: 0 auto 50px;

            background-color: #ffffff;
        }

        .geschaeftsbericht .oebb-dm-dialog {
            margin: 50px auto 50px;
        }

        .oebb-dm-dialog__container {
            padding: 1em;
        }

        .oebb-dm-template {
            display: none;
        }
    `;
    document.head.appendChild(style);
}
