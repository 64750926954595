import { OebbNumberAnimation } from "@oebb-web-team/oebb-web-library/dist/number-animation/OebbNumberAnimation";
export function initNumberAnimation() {
    const elements = document.querySelectorAll(".js-number-animation");
    if (!("IntersectionObserver" in window)) {
        elements.forEach((element) => startAnimation(element));
        return;
    }
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                startAnimation(entry.target);
                observer.unobserve(entry.target);
            }
        });
    }, { threshold: 0.3 }); // Start animation when 30% of component are in viewport
    elements.forEach((element) => observer.observe(element));
}
function startAnimation(element) {
    const numberElement = element.querySelector(".js-animated-number");
    if (!numberElement)
        return;
    const number = numberElement.dataset.number || "0";
    const startValue = numberElement.dataset.startValue ? parseFloat(numberElement.dataset.startValue) : undefined;
    const duration = numberElement.dataset.duration ? parseInt(numberElement.dataset.duration) : undefined;
    const numberFormat = numberElement.dataset.numberFormat;
    const animation = new OebbNumberAnimation(numberElement, number, startValue, duration, numberFormat);
    animation.start();
}
